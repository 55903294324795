import { toast } from 'react-toastify';
import BN from 'bn.js'

import Acala from '@assets/networks/acala.png';
import Algorand from '@assets/networks/algorand.png';
import Arbitrum from '@assets/networks/arbitrum.png';
import Avalanche from '@assets/networks/avalanche.png';
import Binance from '@assets/networks/binance.png';
import BinanceEx from '@assets/networks/binance-ex.png';
import Bitcoin from '@assets/networks/bitcoin.png';
import Centrifuge from '@assets/networks/centrifuge.png';
import Connect from '@assets/networks/connect.png';
import Deribit from '@assets/networks/deribit.png';
import Ethereum from '@assets/networks/ethereum.png';
import Fantom from '@assets/networks/fantom.png';
import Fiat from '@assets/networks/fiat.png';
import Filecoin from '@assets/networks/filecoin.png';
import Fireblocks from '@assets/networks/fireblocks.png';
import FTX from '@assets/networks/ftx.png';
import Interlay from '@assets/networks/interlay.png';
import Kraken from '@assets/networks/kraken.png';
import Kusama from '@assets/networks/kusama.png';
import Polygon from '@assets/networks/matic.png';
import Moonbeam from '@assets/networks/moonbeam.png';
import Offchain from '@assets/networks/offchain.png';
import Optimism from '@assets/networks/optimism.png';
import Parallel from '@assets/networks/parallel.png';
import Polkadot from '@assets/networks/polkadot.png';
import Ripple from '@assets/networks/ripple.png';
import Ronin from '@assets/networks/ronin.png';
import Solana from '@assets/networks/solana.png';
import SolanaBeach from '@assets/networks/solanabeach.png';
import Terra from '@assets/networks/terra.png';
import Tron from '@assets/networks/tron.png';
import ZKSync from '@assets/networks/zksync.png';

interface INotify {
  message: string;
  status?: string;
}

interface IWalletImage {
  [name: string]: string;
}

const walletImages: IWalletImage = {
  acala: Acala,
  algorand: Algorand,
  arbitrum: Arbitrum,
  avalanche: Avalanche,
  binance: BinanceEx,
  bitcoin: Bitcoin,
  bsc: Binance,
  centrifuge: Centrifuge,
  connect: Connect,
  deribit: Deribit,
  ethereum: Ethereum,
  ftx: FTX,
  filecoin: Filecoin,
  fantom: Fantom,
  fiat: Fiat,
  fireblocks: Fireblocks,
  interlay: Interlay,
  kraken: Kraken,
  kusama: Kusama,
  moonbeam: Moonbeam,
  offchain: Offchain,
  optimism: Optimism,
  parallel: Parallel,
  polkadot: Polkadot,
  polygon: Polygon,
  solana: Solana,
  solanabeach: SolanaBeach,
  ripple: Ripple,
  ronin: Ronin,
  terra: Terra,
  tron: Tron,
  zksync: ZKSync,
};

interface INetworkName {
  [name: string]: string;
}

const networkNames: INetworkName = {
  acala: 'Acala',
  algorand: 'Algorand',
  arbitrum: 'Arbitrum',
  avalanche: 'Avalanche',
  binance: 'Binance',
  bitcoin: 'Bitcoin',
  bsc: 'BSC',
  centrifuge: 'Centrifuge',
  deribit: 'Deribit',
  ethereum: 'Ethereum',
  fantom: 'Fantom',
  fiat: 'Fiat',
  filecoin: 'Filecoin',
  fireblocks: 'Fireblocks',
  ftx: 'FTX',
  interlay: 'Interlay',
  kraken: 'Kraken',
  kusama: 'Kusama',
  moonbeam: 'Moonbeam',
  offchain: 'Offchain',
  optimism: 'Optimism',
  parallel: 'Parallel',
  polkadot: 'Polkadot',
  polygon: 'Polygon',
  solana: 'Solana',
  solanabeach: 'Solana Beach',
  ripple: 'Ripple',
  ronin: 'Ronin',
  terra: 'Terra',
  tron: 'Tron',
  zksync: 'ZKSync',
};

interface INetworkTotal {
  [name: string]: number;
}

const networkTotals: INetworkTotal = {
  acala: 0,
  algorand: 0,
  arbitrum: 0,
  avalanche: 0,
  binance: 0,
  bitcoin: 0,
  bsc: 0,
  centrifuge: 0,
  deribit: 0,
  ethereum: 0,
  fantom: 0,
  fiat: 0,
  filecoin: 0,
  fireblocks: 0,
  ftx: 0,
  interlay: 0,
  kraken: 0,
  kusama: 0,
  moonbeam: 0,
  offchain: 0,
  optimism: 0,
  parallel: 0,
  polkadot: 0,
  polygon: 0,
  solana: 0,
  solanabeach: 0,
  ripple: 0,
  ronin: 0,
  terra: 0,
  tron: 0,
  zksync: 0,
};

interface INetworkId {
  [name: string]: string;
}

export const networkIds: INetworkId = {
  '1': 'ethereum',
  '10': 'optimism',
  '56': 'bsc',
  '137': 'polygon',
  '250': 'fantom',
  '1284': 'moonbeam',
  '2020': 'ronin',
  '42161': 'arbitrum',
  '43114': 'avalanche',
  '1399811149': 'solana',
};

interface INetworkTxnLink {
  [name: string]: string;
}
export const networkTxnLinks: INetworkTxnLink = {
  'bitcoin': 'https://www.blockchain.com/explorer/transactions/btc/',
  'ethereum': 'https://etherscan.io/tx/',
  'optimism': 'https://optimistic.etherscan.io/tx/',
  'bsc': 'https://bscscan.com/tx/',
  'polygon': 'https://polygonscan.com/tx/',
  'fantom': 'https://ftmscan.com/tx/',
  'moonbeam': 'https://moonscan.io/tx/',
  'ronin': 'https://explorer.roninchain.com/tx/',
  'arbitrum': 'https://arbiscan.io/tx/',
  'avalanche': 'https://avascan.info/blockchain/c/tx/',
  'solana': 'https://explorer.solana.com/tx/',
};
class UtilsService {
  public getWalletImage(value: string) {
    return walletImages[value];
  }

  public getNetworkName(value: string) {
    const name = networkNames[value];
    if (name) return name;
    return value;
  }

  public getNetworkFromId(value: number) {
    // console.log('getNetworkFromId', value, networkIds[value.toString()]);
    return networkIds[value.toString()];
  }

  public getNetworkTotals() {
    return Object.assign({}, networkTotals);
  }

  public getNetworkTxnLink(value: string) {
    const link = networkTxnLinks[value];
    if (link) return link;
    return '';
  }

  public abbreviateNumber(value, options?: { currency?: string, preciseLength?: boolean }) {
    let newValue = value;
    const suffixes = ['', 'K', 'M', 'B', 'T'];
    let suffixNum = 0;
    while (newValue >= 1000) {
      newValue /= 1000;
      suffixNum++;
    }

    newValue = newValue.toPrecision(options?.preciseLength ? newValue.length : 3);

    newValue += suffixes[suffixNum];
    return `${options?.currency === 'usd' ? '$' : ''}${newValue}`;
  }

  public capitalizeLetter = (str: string) => (str && str[0].toUpperCase() + str.slice(1)) || '';

  public truncate = (str: string, len: number) => (str && str.length > len ? str.substring(0, len) + '...' : str || '');

  public shrinkTxHash = (address: string) => {
    if (address) {
      return `${address.slice(0, 10)}...${address.substring(address.length - 10, address.length)}`;
    }
    return '';
  };

  public shrinkWalletAddress(address: string) {
    if (address?.length > 10) {
      return `${address.slice(0, 6)}...${address.substring(address.length - 4, address.length)}`
    }
    return address || '';
  }

  public shrinkNumber(s: string | number) {
    let n = 0;
    if (typeof s === 'number') {
      n = s;
    } else if (typeof s === 'string') {
      n = Number.parseFloat(s || '');
    } 
    if (n === 0) return '0.0';
    if (n > 999999999) return n.toExponential(4);
    return n.toFixed(4);
  }

  public getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  public serialize(obj) {
    let str = [];
    for (let p in obj)
      if (obj.hasOwnProperty(p) && obj[p]) {
        // @ts-ignore: Unreachable code error
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return str.join('&');
  }

  getRawAmount(amount: number, decimals: number) {
    return this.toBaseUnit(amount.toString(), decimals);
  }

  getNumberAmount(rawAmount: string, decimals: number = 18) {
    return +rawAmount / 10 ** decimals;
  }

  isString(s) {
    return (typeof s === 'string' || s instanceof String)
  }

  toBaseUnit(value, decimals) {
    if (!this.isString(value)) {
      throw new Error('Pass strings to prevent floating point precision issues.')
    }
    const ten = new BN(10);
    const base = ten.pow(new BN(decimals));

    // Is it negative?
    let negative = (value.substring(0, 1) === '-');
    if (negative) {
      value = value.substring(1);
    }

    if (value === '.') {
      throw new Error(
        `Invalid value ${value} cannot be converted to`
        + ` base unit with ${decimals} decimals.`);
    }

    // Split it into a whole and fractional part
    let comps = value.split('.');
    if (comps.length > 2) { throw new Error('Too many decimal points'); }

    let whole = comps[0], fraction = comps[1];

    if (!whole) { whole = '0'; }
    if (!fraction) { fraction = '0'; }
    if (fraction.length > decimals) {
      throw new Error('Too many decimal places');
    }

    while (fraction.length < decimals) {
      fraction += '0';
    }

    whole = new BN(whole);
    fraction = new BN(fraction);
    let wei = (whole.mul(base)).add(fraction);

    if (negative) {
      wei = wei.neg();
    }

    return new BN(wei.toString(10), 10).toString();
  }

  public notify(options: INotify) {
    if (options?.status === 'success') {
      toast.success(options.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.error(options.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#fae9e9',
          color: '#ce5f6d',
        },
      });
    }

  }
}

export const utilsService = new UtilsService();
